@import '@styles/_variables.scss';
.ab-cards {
  padding: 100px Max(113px, calc((100% - 1440px) / 2 + 113px)) 50px;
  background-color: #fff;

  @media screen and (max-width: $tablet-breakpoint) {
    padding: 68px 30px 34px;
  }

  &.beige {
    background-color: #f9f6ef;
  }

  &.two {
    & > .row {
      overflow: initial;
      @media screen and (max-width: $tablet-breakpoint) {
        overflow-x: auto;
      }
      & > .services-card {
        flex: initial;
        flex-shrink: 0;
        width: 50%;
        max-width: 590px;
        max-height: 450px;
        @media screen and (max-width: $tablet-breakpoint) {
          width: 222px;
          max-width: 222px;
          max-height: 323px;
        }
        & > .image {
          height: 222px;
          padding-top: initial;
        }
      }
    }
  }

  &.long-title {
    & > .title {
      h2 {
        max-width: 646px;
        width: 100%;
      }
    }
  }

  &.center {
    & > .row {
      & > .cross-selling-card {
        & > span {
          text-align: center;
        }
      }
    }
  }

  & > .title {
    flex-direction: row;
    justify-content: space-between;
    order: 0;
    margin-bottom: 30px;
    @media screen and (max-width: $tablet-breakpoint) {
      flex-direction: column;
    }
    h2 {
      width: 100%;
      max-width: 590px;
      color: #372f2f;
    }

    & > .ab-paragraph-text {
      width: 100%;
      max-width: 378px;
      color: #8c8181;
      @media screen and (max-width: $tablet-breakpoint) {
        margin-top: 22px;
      }
    }
  }

  & > .row {
    flex-direction: row;
    order: 2;
    // justify-content: space-between;
    overflow-x: auto;
    padding-bottom: 50px;
    // margin-left: Min(-113px, calc(-1 * ((100vw - 1440px) / 2 + 113px)));
    // margin-right: Min(-113px, calc(-1 * ((100vw - 1440px) / 2 + 113px)));
    // padding-left: Max(113px, calc((100vw - 1440px) / 2 + 113px));
    // padding-right: Max(113px, calc((100vw - 1440px) / 2 + 113px));
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: $tablet-breakpoint) {
      margin-left: -30px;
      margin-right: -30px;
      padding-right: 30px;
      padding-left: 30px;
    }

    .services-card:first-child:nth-last-child(2),
    .services-card:first-child:nth-last-child(2) ~ .services-card {
      & > .image {
        padding-top: 70%;
        @media screen and (max-width: $tablet-breakpoint) {
          padding-top: 100%;
        }
      }
    }

    & > .services-card {
      & + .services-card {
        margin-left: 35px;
        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: 12px;
        }
      }
    }

    & > .cross-selling-card {
      & + .cross-selling-card {
        margin-left: 35px;
        @media screen and (max-width: $tablet-breakpoint) {
          margin-left: 12px;
        }
      }
    }
  }

  & > .ab-link {
    &.before {
      order: 1;

      margin-bottom: 33px;
      align-self: flex-end;

      @media screen and (max-width: $tablet-breakpoint) {
        order: 3;
        align-self: center;
      }
    }
    order: 3;
    @media screen and (max-width: $tablet-breakpoint) {
      align-self: center;
      margin-top: 0px;
    }
  }
}
