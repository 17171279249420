@import "@styles/_variables.scss";
.cross-selling-card {
  // width: 381px;
  display: flex;
  flex: 1;

  @media screen and (max-width: $tablet-breakpoint) {
    flex: initial;
    flex-shrink: 0;
    width: 222px;
  }

  & > .image {
    width: 100%;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 16px;

    & > span {
      & > img {
        transition: transform 500ms ease-in-out;
        transform: scale(1.1);
        &:hover {
          transform: scale(1);
        }
      }
    }
  }

  & > span {
    width: 100%;
    height: auto;
    max-height: 381px;
    & > img {
      width: 100%;
      height: auto;
      max-height: 381px;
      object-fit: cover;
      margin-bottom: 26px;

      @media screen and (max-width: $tablet-breakpoint) {
        width: 222px;
        height: 250px;
        max-height: initial;
      }
    }
  }

  & > .ab-cta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 500ms;
  }

  & > a {
    margin-top: 37px;
  }
}
