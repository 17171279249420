@import "@styles/_variables.scss";
.services-card {
  flex: 1;
  display: flex;
  flex-shrink: 0;
  @media screen and (max-width: $tablet-breakpoint) {
    width: 222px;
    flex: initial;
    flex-shrink: 0;
  }
  & > .image {
    width: 100%;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;

    & > span {
      & > img {
        transition: transform 500ms ease-in-out;
        transform: scale(1.1);
        &:hover {
          transform: scale(1);
        }
      }
    }
  }

  & > .tile {
    font-family: "Silk";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #372f2f;
    margin-top: 27px;
  }
}
